<template>
    <div class="breadcrumb-container">
        <div class="bc-item" v-for="(item, index) in items" :key="index">
            <router-link :to="item.path">
                <span class="bc-item__label">{{ item.name }}</span>
            </router-link>
            <span v-if="index !== items.length - 1" class="arrow-item">
                <IconSax name="arrow-right-3" size="1rem"  />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb",
    props: {
        items: {
            type: Array,
            default: () => ["/"],
        },
    },
    data() {
        return {

        }
    },
    methods: {

    },
};

</script>

<style lang="scss" scoped>
.breadcrumb-container {
    @include flex-row;
    align-items: center;
}

.bc-item {
    @include link;
    @include flex-row;
    align-items: center;

    &__label {
        color: var(--maincolor);
    }
}

.bc-item:last-child * {
    color: var(--neutral-gray-400) !important;
    cursor: default;
}

.arrow-item {
    margin: 0 0.25rem;

    ::v-deep .svgCss {
        svg {
            width: 0.625rem;
            height: 0.625rem;
        }

        svg path {
            fill: var(--maincolor);
        }
    }
}
</style>